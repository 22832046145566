import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/welcome",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Welcome")),
  },
  {
    exact: true,
    path: "/termsandcondtions",
    component: lazy(() => import("src/views/auth/LogIn/Termsandconditon")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Editprofile/index")),
  },
  {
    exact: true,
    path: "/trade",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Trade/")),
  },
  {
    exact: true,
    path: "/bank-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BankDetails")),
  },
  {
    exact: true,
    path: "/my-wallet",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/index")),
  },

  {
    exact: true,
    path: "/vendor",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Vendor/index")),
  },
  {
    exact: true,
    path: "/vendor-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Vendor/VendorDetail")),
  },
  {
    exact: true,
    path: "/transaction-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transaction")),
  },
  {
    exact: true,
    path: "/redeem-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Redeemhistory")),
  },

  {
    exact: true,
    path: "/my-wallet/approve",
    component: lazy(() => import("src/views/pages/MyWallet/Approve")),
  },
  {
    exact: true,
    path: "/dashboard/approve",

    component: lazy(() => import("src/views/pages/MyWallet/Approve")),
  },
  {
    exact: true,
    path: "/token/approve",
    component: lazy(() => import("src/views/pages/MyWallet/Approve")),
  },
  {
    exact: true,
    path: "/notifications",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },
  {
    exact: true,
    path: "/Fauth",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/2FA/index")),
  },
  {
    exact: true,
    path: "/buy-sell",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Buy/")),
  },
  {
    exact: true,
    path: "/terms",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/user-info",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/user-info/index")),
  },
  {
    exact: true,
    path: "/get-new-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/instrauctions",

    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/verify-email-otp",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/VerifyEmailOtp")),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/buy",
    guard: true,
    component: lazy(() => import("src/views/pages/Trade/Buy/")),
  },
  {
    exact: true,
    path: "/sell",
    guard: true,
    component: lazy(() => import("src/views/pages/Trade/Sell/")),
  },
  {
    exact: true,
    path: "/support",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    path: "/profile-setup",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/Setting")),
  },

  {
    exact: true,
    path: "/edit-kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings/KycVerification")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
