import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { TopBarData } from "src/layouts/HomeLayout/TopBar";

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    padding: "10px 10px",
    background:
      "radial-gradient(39.95% 25.73% at 89.95% 58.17%, rgba(91, 153, 234, 0.5) 0%, rgba(80, 155, 252, 0) 100%), radial-gradient(61.45% 39.57% at -11.45% 38.73%, rgba(71, 168, 220, 0.5) 0%, rgba(71, 168, 220, 0) 100%), radial-gradient(66.33% 35.43% at 86.68% 9.61%, rgba(97, 159, 240, 0.5) 0%, rgba(90, 155, 240, 0) 100%),#FFFFFE",
    borderBottom: "2px solid rgb(237 244 254)",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#FF2626"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10 }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "#244273" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
