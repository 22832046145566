import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { calculateTimeLeft } from "./timer";
import jwtDecode from "jwt-decode";
export const AuthContext = createContext();

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  console.log("decoded", decoded);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isloading, setIsLoading] = useState(false);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  let data = {
    isValidToken: (token) => isValidToken(token),
    userLoggedIn: isLogin,
    userData,
    kycData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    isloading,
    setIsLogin,
    getProfileHandler: (data) => getProfileHandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  const getProfileHandler = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(apiConfig.myAccount, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        setUserData(res?.data?.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setLoader(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfileHandler();
  }, []);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
