import React from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,

    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
    backgroundColor: "#ebf3fb",
  },
  loader1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    width: "350px",
    "& img": {
      width: "350px",
    },
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.loader1}>
        <Box className={classes.loader}>
          <img src="/images/LoginLayout.svg" alt="loader" />
        </Box>
      </Box>
    </div>
  );
}
