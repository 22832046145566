/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import {
  FaTachometerAlt,
  FaUniversity,
  FaWallet,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaPhoneSquareAlt,
  FaHistory,
  FaUserEdit,
  FaLock,
} from "react-icons/fa";
import { MdSell } from "react-icons/md";
import { VscSymbolEvent } from "react-icons/vsc";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import { MdSettings } from "react-icons/md";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import ChartList from "./ChartList";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: FaTachometerAlt,
        href: "/dashboard",
      },
      {
        title: "My Wallet",
        icon: FaWallet,
        href: "/my-wallet",
      },

      {
        title: "Vendor",
        icon: VscSymbolEvent,
        href: "/vendor",
      },
      {
        title: "Transaction History",
        icon: FaHistory,
        href: "/transaction-history",
      },
      {
        title: "Redeem History",
        icon: FaHistory,
        href: "/redeem-history",
      },
      {
        title: "Settings",
        icon: MdSettings,
        href: "/profile-setup",
      },

      {
        title: "Support",
        icon: FaPhoneSquareAlt,
        href: "/support",
      },
      {
        title: "Instant Buy/Sell",
        icon: MdSell,
        href: "/buy-sell",
      },
    ],
  },
];

const sectionsBelow = [
  {
    items: [
      {
        title: "Terms & Condition",

        href: "/terms",
      },
      {
        title: "Privacy Policy",

        href: "/privacy-policy",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#000",
  },
  desktopDrawer: {
    width: 270,
    top: 0,
    height: "100%",
    background:
      "radial-gradient(39.95% 25.73% at 89.95% 58.17%, rgba(91, 153, 234, 0.5) 0%, rgb(80 155 252 / 18%) 100%), radial-gradient(61.45% 39.57% at -11.45% 38.73%, rgba(71, 168, 220, 0.5) 0%, rgba(71, 168, 220, 0) 100%),radial-gradient(66.33% 35.43% at 86.68% 9.61%, rgba(97, 159, 240, 0.5) 0%, rgb(90 155 240 / 0%) 100%), #ffffff",
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  mainNavigation: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background:
      "radial-gradient(39.95% 25.73% at 89.95% 58.17%, rgba(91, 153, 234, 0.5) 0%, rgba(80, 155, 252, 0) 100%), radial-gradient(61.45% 39.57% at -11.45% 38.73%, rgba(71, 168, 220, 0.5) 0%, rgba(71, 168, 220, 0) 100%),radial-gradient(66.33% 35.43% at 86.68% 9.61%,rgba(97, 159, 240, 0.5) 0%,rgba(90, 155, 240, 0) 100%),#FFFFFF",
  },
  logoBox: {
    "& .textBox": {
      display: "flex",
      alignItem: "center",
      paddingRight: "8px",
      justifyContent: "space-between",
    },
    paddingTop: "3px",
    paddingLeft: "15px",
    cursor: "pointer",
    "& h2": {
      color: "#244273",
      fontSize: "25px",
      marginTop: "3px",
    },
    "& h6": {
      color: "#000000",
      fontSize: "15px",
      fontWeight: "400",
      marginTop: "10px",
    },
    "& img": {
      maxWidth: "100% !important",
      height: "auto",
    },
  },
  logoutButton: {
    padding: "1px 0px 0px 20px",
    background: "#FFFFFF",
    borderRadius: "10px",
    margin: "0px 10px 0px 15px",
    cursor: "pointer",
    "& span": {
      color: "#244273",
    },
    "& svg": {
      fontSize: "20px",
    },
    "&:hover": {
      backgroundColor: "#244273",
      "& svg": {
        fontSize: "20px",
        color: "#fff",
      },
      "& span": {
        color: "#fff",
      },
    },
    "&.active": {
      backgroundColor: "#244273",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box className={classes.mainNavigation}>
      <Box className="scrollbar-container ps">
        {open && (
          <ConfirmationDialog
            open={open}
            handleClose={() => setOpen(false)}
            title={"Logout"}
            desc={"Do you want to Logout"}
            style={{ color: "red" }}
          />
        )}
        <Box padding={0} className={classes.logoBox}>
          <Logo onClick={() => history.push("/dashboard")} alt="Logo" />
        </Box>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box my={3}>
            {sections.map((section, i) => {
              return (
                <>
                  <List
                    key={`menu${i}`}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname,
                    })}
                  </List>
                </>
              );
            })}
            <List className={classes.logoutButton}>
              <ListSubheader
                disableGutters
                disableSticky
                onClick={() => setOpen(true)}
              >
                <FaLock /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Logout</span>
              </ListSubheader>
            </List>
          </Box>
          <Box className="side_nev_Bottom">
            {sectionsBelow.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {section.items.map((itemList, i) => {
                  return (
                    <Button
                      fullWidth
                      key={i}
                      style={{
                        justifyContent: "start",
                        paddingLeft: 25,
                        borderRadius: 0,
                        textTransform: "capitalize",
                        color: "#244273",
                      }}
                      onClick={() => history.push(itemList.href)}
                    >
                      {itemList.title}
                    </Button>
                  );
                })}
              </List>
            ))}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
