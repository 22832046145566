import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";

import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import SessionOutModal from "src/component/SesseionOutModal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",

    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";
  const [notificationList, setNotificationList] = useState([]);
  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opensession, setOpensession] = useState(false);

  const getNotifictionList = async () => {
    const isvalid = auth.isValidToken(window.sessionStorage.getItem("token"));
    if (!isvalid) {
      setOpensession(true);
    }
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });
      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };

  const readNotificationHandler = async () => {
    try {
      const res = await Axios.get(ApiConfig.readNotification, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 1617) {
        setLoader(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);
  const handleChange = () => {
    readNotificationHandler();
    setCount([]);
    history.push("/notifications");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {opensession && (
        <SessionOutModal open={opensession} setOpensession={setOpensession} />
      )}
      <Logo
        style={{
          paddingTop: "0px",
          cursor: "pointer",
          width: "50px",
        }}
      />

      <Box flexGrow={1} />

      <IconButton
        style={{
          marginRight: "5px",
          border: "1px solid rgb(235 243 251)",
          backgroundColor: "rgb(194 226 243)",
        }}
        onClick={handleChange}
      >
        <Badge badgeContent={count?.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <SwipeableTemporaryDrawer />
    </>
  );
}
