// const url = "https://java.globalREIT.io";
// const url = "http://172.16.0.90:3023";
// const url = "https://java-blockchainapp.mobiloitte.org";
const url = "https://java-waseemtest.mobiloitte.org";

const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const wallet = `${url}/wallet`;
const static1 = `${url}/static`;
const admin = `${url}/wallet/admin`;
const admin1 = `${url}/wallet/wallet`;
const transactionnewvendor = `${url}`;

const notification = `${url}/notification`;

const ApiConfig = {
  userLogin: `${auth}`,
  verifyGoogle: `${auth}/verify-google`,

  getCoinList: `${url}/wallet/coin/get-coin-list`,
  userSignUp: `${user}/signup`,
  verifyOTP: `${user}/verify-user`,
  resendOTP: `${user}/resend-verify-email`,
  forgotPassword: `${user}/forget-password/mobiloitApp`,
  verifyOTPSMS: `${user}/verify-sms-code-mobile-app`,
  resetPassword: `${user}/reset-password-mobile-app`,
  getKYCDetails: `${account}/get-kyc-details`,
  ApitoChangePassword: `${account}/change-password`,
  policy: `${static1}/get-all-static-content-details-for-web`,

  getstatusonblock: `${admin1}/get-hot-wallet-status`,

  submitSupportRequest: `${static1}/submit-support-ticket`, // to send requst for ticket
  viewTicketDetail: `${static1}/view-ticket-detail`, //to get details of tickets
  getReplySupportTicketData: `${static1}/get-ticket-reply-data`, //to get reply data from admin
  addSupportTicketReply: `${static1}/add-support-ticket-reply`,
  getUserSupportTicket: `${static1}/get-user-support-ticket`, //to requset ticket from User side
  updateReplyTicket: `${static1}/update-reply-support-ticket`, //to update reply Ticket

  // editUserProfile: `${user}/profile-update`,
  getCountryList: `${user}/get-country-list`,
  getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

  addSubscribe: `${user}/add-subscribe`,

  // Ticket    search-and-filter-ticket-list
  searchAndFilterTicketList: `${user}/search-and-filter-ticket-list`,

  // account

  myAccount: `${account}/my-account`,
  userBankAccount: `${account}/add-user-bank-account`,
  userAccountDetails: `${account}/get-user-account-detail`,
  userAccountList: `${account}/get-user-account-list`,
  userAccountDelete: `${account}/delete-user-bank-account`,
  saveKycDetails: `${account}/save-kyc-details`,
  uploadFile: `${account}/upload-file`,
  getKycList: `${account}/get-all-kyc-details`,
  editUserProfile: `${account}/profile-update`,
  CountryList: `${account}/get-country-list`,
  googleauthtwostep: `${account}/google-auth-mobile-app`,
  getauthmobilecode: `${account}/verify-google-code`,
  disabletwofa: `${account}/twoFa-disable`,
  venderDetails: `${account}/admin/user-management/vendor-search-filter`,
  venderDetailsview: `${account}/admin/user-management/user-details`,

  // wallet

  myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
  // static
  contactUS: `${static1}/submit-support-ticket`,
  postnewsLetter: `${static1}/post-news-letter-to-user`,
  faq: `${static1}/search-filter-faq-list?page=0&pageSize=10`,
  term: `${static1}/get-static-page-data-by-page-key?pageKey=Terms_And_Conditions`,
  // policy: `${static1}/get-static-page-data-by-page-key`,
  myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  redeemtobank: `${wallet}/wallet/request-redeem-gret-to-admin`,
  toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,
  myAddress: `${wallet}/wallet/get-address-new`,
  withdraw: `${wallet}/wallet/withdraw`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,
  redremvendoraddress: `${wallet}/wallet/get-address-for-redeem`,

  cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
  deposits: `${wallet}/wallet/get-deposits`,

  buyTokenBtc: `${wallet}/wallet/add-buy-token`,

  // sellToken: `${wallet}/wallet/sell-token`,
  sellToken: `${wallet}/wallet/add-sell-token`,

  redeemtosenduser: `${wallet}/wallet/redeem-gret-amount-transfer-to-user`,
  redeemtosendvender: `${wallet}/wallet/redeem-gret-tranfer-to-admin`,

  redeemtvenderhistorydata: `${wallet}/get-redeem-ventor-transaction-history`,

  // admin
  transactionHistoy: `${wallet}/get-all-transaction-history`,
  getTransactionredeemhistory: `${wallet}/get-all-redeem-transaction-history`,

  allcointotal: `${wallet}/count-total-amount-with-name`,
  cointotalbalance: `${wallet}/count-total-amount-with-fkUserId`,

  // venderDetails: `${wallet}/user-management`,

  // notification
  getNotification: `${notification}/get-notification-data`,
  readNotification: `${notification}/read-notification`,
  clearNotification: `${notification}/clear-notification`,

  // account
};

export default ApiConfig;
